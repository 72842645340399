body, p, small, strong, span {
  font-family: Montserrat,Open Sans,Lato,Oswald,Nunito,Questrial,Didact Gothic,Muli,sans-serif,arial,helvetica;;
  color: #727272;
}

h1, h2, h3, h4, h5, h6, button, label, .card-header {
  font-family: Montserrat,Open Sans,Lato,Oswald,Nunito,Questrial,Didact Gothic,Muli,sans-serif,arial,helvetica;;
  color: #727272;
}

.main-container {
  height: 100vh;
  display: flex;
}

.App-login {
  background-color: white;
  height: 100vh;
  
  .card {
    box-shadow: 0 0 .8125rem rgba(0,0,0,.13);
    transition: display .5s ease-in-out 1.2s;
    color: #707070;
  }
}

.main-content {
  background-color: #d1eefa;
  height: 100vh;
  overflow: hidden;
  & .nav-link {
    color: #727272;
    font-size: 1.5rem;
    padding: 0px;
    margin-right: 2rem;
  }
  
  & .nav-link.active {
    border-bottom: 5px solid #009fe3;
  }
  
}

.container-top-bar {
  width: 100%;
  height: 5.25rem;
  background-color: #d1eefa;
  align-items: center;
  justify-content: space-between;
  display: flex;
  
  &  .img-logo-bis {
    width: 185px;
  }
  
}

.inner-content {
  height: calc(100% - 5.25rem);
  background: #f1f1f1;
  border-top-left-radius: 30px;
  overflow: auto;
}

.label-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-style: italic;
  font-size: 15px;
  font-family: Montserrat;
  line-height: 2.1875rem;
  color: #1b2f33 !important;
  white-space: nowrap;
  margin-left: 15px;
}

.img-responsive {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.full-width {
  width: 100%;
}

.main-sidebar {
  background: #d1eefa;
  
  .nav {
    margin-top: 50px;
  }
  
  .subtitle-link {
    background: transparent;
    color: rgba(0,0,0,.87);
    letter-spacing: -1px !important;
    border-bottom: 1px solid #d1eefa;
    padding-top: 10px;
    padding-bottom: 10px;
    
    & > a {
      color: #1b2f33;
      text-decoration: none;
    }
  }
  
  .dropdown-menu {
    padding: 0px;
  }
  
  .nav-link {
    text-align: center;
    margin-top: 1rem;
    color: #000000;
    padding: 1rem 0rem;
    font-size: 13px;
    
    & > span {
      font-size: 2rem;
      text-align: center;
      display:block;
    }
    
    &.active {
      background: #fff;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      & > span {
        color: #1b2f33 !important;
      }
      
      & > label {
        font-size: 13px;
        font-weight: 500;
        transition: all .3s ease-in-out;
        letter-spacing: -1px;
        color: #1b2f33 !important;
      }
      
    }
  } 
  
  box-shadow: 2px 0px rgba(0,0,0, 0.3); 
}

.btn-filters {
  float: right;
  border-radius: 4px;
  background-color: #009fe3 !important;
  color: #fff;
  border-color: #fff;
}

.btn-charts {
  position: absolute;
  z-index: 1;
  right: 10%;
  margin-right: 1rem;
}

.btn-charts2 {
  position: absolute;
  z-index: 1;
  right: 10%;
  margin-right: 60px;
}

.btn-info {
  background-color: rgba(31, 186, 219,1);
  border-color: rgba(31, 186, 219, 0.7);
}

.right-sidebar {
  width: 100vw;
  padding: 15px;
}

.btn-filters-new {
  font-size: 14px;
  margin-top: 5px;
}

.react-datepicker-wrapper {
  width: 100%;
  & > div > input {
    width: 100%;
  }
}

.logo-mobile {
  max-width:80vw;
}

@media (min-width: 768px) { 
  .right-sidebar {
    width: 20vw;
    padding: 15px;
  }
}

@media(max-width: 767px) {
  .btn-filters-absolute-mobile {
    position: absolute;
    top: 3rem;
    right: 15px;
  }
}

.filter-close {
  color: rgba(31, 186, 219,1);
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.tabs-table-graphs {
  .nav-link {
    margin-right: 0px;
    padding: 10px;
  }
}

.tab-content {
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  
}

.st0{fill:#A5A5A5;}
.st1{fill:#AFAFAF;}
.st2{fill:#B9B9B9;}
.st3{fill:#E1E1E1;}
.st4{fill:#424241;}
.st5{font-family:'Lato-Bold';}
.st6{font-size:10px;}
.st7{display:none;}
.st8{display:inline;}
.st9{enable-background:new    ;}
.st10{fill:none;stroke:#E1E1E1;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2;}
.st11{fill-rule:evenodd;clip-rule:evenodd;fill:#A5A5A5;}
.st12{opacity:5.000000e-02;}
.st13{fill:#1B1B19;stroke:#E1E1E1;stroke-miterlimit:10;}
.st14{fill-rule:evenodd;clip-rule:evenodd;fill:#F6F6F6;}
.st15{fill:#F6F6F6;stroke:#E1E1E1;stroke-width:0.5;stroke-miterlimit:10;}
.st16{fill:none;stroke:#E1E1E1;stroke-width:0.5;stroke-miterlimit:10;}
.st17{fill:#F6F6F6;}
.st18{fill:#E7E7E7;}
.st19{fill:#A57D24;}
.st20{fill:url(#SVGID_1_);}
.st21{opacity:0.6;}
.st22{fill:#A5A5A5;stroke:#A57D24;stroke-width:0.2;stroke-miterlimit:10;}
.st23{fill:#A5A5A5;stroke:#A57D24;stroke-miterlimit:10;}
.st24{opacity:0.7;fill:#424241;}
.st25{font-size:9px;}
.st26{fill:none;stroke:#424241;stroke-width:0.5;stroke-miterlimit:10;}
.st27{fill:#1FBADB;}
.st28{fill:#FFFFFF;}
.st29{fill:#0081C6;}
.st30{fill:#C5A528;}
.st31{fill:#BA5922;}
.st32{fill:#A0BD8E;}

.tst1{enable-background:new 0 0 1168.8 841.9;}
.tst2{fill: rgb(184, 184, 184);}
.sc1{stop-color:#B38E34;}
.sc2{stop-color:#A57D24;}
.sc3{stop-color:#F1DD80;}
.sc4{stop-color:#FBF2A8;}

#layer_1 { max-height:80vh; transform: rotate(-30deg); }

.cls-1,.cls-2,.cls-3{fill:none;stroke:#000;stroke-miterlimit:10;}.cls-2{stroke-width:0.56px;}.cls-3{stroke-width:0.4px;}
